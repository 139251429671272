import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  toastContainer: {
    backgroundColor: colors.midTransparentBlack,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  mainText: {
    fontFamily: fonts.bold,
    fontSize: RFValue(15),
    color: colors.white,
  },
  description: {
    fontFamily: fonts.medium,
    fontSize: RFValue(12),
    color: colors.white,
  },
  icon: {
    fontSize: RFValue(20),
    marginRight: 10,
    margin: "auto",
  },
});

export { styles };
