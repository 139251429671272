import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { AntDesign, Foundation } from "@expo/vector-icons";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { DocumentData, Timestamp } from "firebase/firestore";
import { useEffect, useReducer, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import * as yup from "yup";

import { DefaultButton } from "../../components/Buttons/DefaultButton";
import { HeaderWithGoBack } from "../../components/Headers/HeaderWithGoBack";
import { InputWithLabel } from "../../components/Inputs/InputWithLabel";
import { useAuth } from "../../hooks/useAuth";
import {
  RegistrationDataActionKind,
  registrationDataReducer,
  registrationDataReducerInitialState,
} from "../../reducers/registrationData/registrationDataReducer";
import { getProviderByKey } from "../../services/firestore/provider/getProviderByKey";
import { getColor } from "../../styles/colors";
import { styles } from "./styles";

import { updateUserApi } from "../../services/api/user/updateUserApi";

import { UserProps } from "../../models/userProps";
import { joinObjects } from "../../utils/dataValidation/joinObjects";

import Toast from "react-native-toast-message";
import { RadioInput } from "../../components/Inputs/RadioInput";

import {
  addDays,
  format,
  isBefore,
  isValid,
  parse,
  Locale,
  parseISO,
  formatISO,
} from "date-fns";
import { BasicLoading } from "../../components/Loadings/BasicLoading";
import { useCompany } from "../../hooks/useCompany";
import { getUser } from "../../services/firestore/user/getUser";
import { validateObject } from "../../utils/dataValidation/validateObject";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ValidationInput } from "../../components/Inputs/ValidationInput";
import { isDateStringValid } from "../../utils/date/isDateStringValid";
import { defineDateNationality } from "../../utils/date/defineDateNationality";

interface RegistrationDataForm {
  name: string;
  lastName: string;
  cpf: string;
  email: string;
  phone: string;
  birthdate: string;
  gender: string;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required("O nome é um campo obrigatório")
    .min(3, "O nome deve ter no mínimo 3 caracteres"),
  lastName: yup
    .string()
    .required("O nome é um campo obrigatório")
    .min(3, "O nome deve ter no mínimo 3 caracteres"),
  email: yup
    .string()
    .required("O email é um campo obrigatório!")
    .email("Insira um email válido!"),
  cpf: yup.string().required("CPF é um campo obrigatório"),
  phone: yup.string().required("Telefone é um campo obrigatório"),
  birthdate: yup
    .string()
    .required("Data de nascimento é um campo obrigatório")
    .min(10, "Preencha a data de nascimento")
    .test("isValidDate", "Insira uma data válida", (value) => {
      const parsedDate = parse(value, "dd/MM/yyyy", new Date());

      return isValid(parsedDate);
    })
    .test(
      "isNotInFuture",
      "A data de nascimento não pode estar no futuro!",
      (value) => {
        const parsedDate = parse(value, "dd/MM/yyyy", new Date());

        return isBefore(parsedDate, new Date());
      }
    )
    .test("isNotTooOld", "A idade máxima é 120 anos!", (value: any) => {
      const parsedDate = parse(value, "dd/MM/yyyy", new Date());
      const minDate = new Date();
      const maxAge = 120;
      minDate.setFullYear(minDate.getFullYear() - maxAge);

      return isBefore(minDate, parsedDate);
    }),
  gender: yup.string().required("O gênero é um campo obrigatório!"),
});

const RegistrationData = ({ navigation }: NativeStackHeaderProps) => {
  const { user, userToken } = useAuth();
  const { company } = useCompany();

  const colors = getColor({ company });

  const [registrationDataState, dispatchRegistrationDataState] = useReducer(
    registrationDataReducer,
    registrationDataReducerInitialState
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [provider, setProvider] = useState<DocumentData>({});

  const [loadingProfileData, setLoadingProfileData] = useState(false);

  const [updatedUser, setUpdatedUser] = useState<UserProps>(user);

  const { control, setValue, getValues } = useForm<RegistrationDataForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleGetUpdatedUser = async () => {
    const response = await getUser({
      cpf: user.cpf,
      registration: user.registration,
    });
    let updatedUser = response.data;

    if (updatedUser.birthdate instanceof Timestamp) {
      updatedUser.birthdate = formatISO(updatedUser.birthdate.toDate());
    }

    setUpdatedUser((updatedUser as UserProps) || user);
  };

  const handleEditUser = async (props: RegistrationDataForm) => {
    try {
      setLoadingProfileData(true);

      await schema.validate(props, { abortEarly: false });

      let updatedUserData: UserProps = joinObjects(updatedUser, props);

      updatedUserData.birthdate = parse(
        updatedUserData.birthdate,
        "dd/MM/yyyy",
        new Date()
      ).toISOString();

      const response: any = await updateUserApi(
        {
          id: user.id,
          userData: {
            ...updatedUserData,
            cpf: updatedUserData.cpf?.replace(/\D+/g, ""),
            phone: updatedUserData.phone?.replace(/\D+/g, ""),
            identificationType: 9,
            identificationValue: updatedUserData.cpf?.replace(/\D+/g, ""),
          },
        },
        userToken
      );

      setIsEditing(false);
      setLoadingProfileData(false);

      if (response.error) {
        dispatchRegistrationDataState({
          type: RegistrationDataActionKind.SET_FULL_DATA,
          cpf: updatedUser.cpf,
          email: updatedUser.email,
          gender: updatedUser.gender,
          name: updatedUser.name,
          lastName: updatedUser.lastName,
          phone: updatedUser.phone,
          birthdate: format(parseISO(updatedUser.birthdate), "dd/MM/yyyy"),
        });

        Toast.show({
          type: "error",
          text2: "Não foi possível editar os seus dados. Tente mais tarde...",
        });

        return;
      }
      Toast.show({ type: "success", text2: "Dados atualizados com sucesso!" });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        Toast.show({
          type: "error",
          text2: `Verifique seus dados. ${error.message}`,
        });
      }
      return;
    }
  };

  useEffect(() => {
    const handleGetProvider = async () => {
      if (updatedUser.provider) {
        const response = await getProviderByKey(updatedUser.provider);

        setProvider(response.data);
      }
    };

    const validatedUpdatedUser = validateObject(updatedUser);

    Object.keys(validatedUpdatedUser)
      .filter((key) => key !== "birthdate")
      .map((key: any) => {
        setValue(key, validatedUpdatedUser[key], { shouldValidate: true });
      });

    dispatchRegistrationDataState({
      type: RegistrationDataActionKind.SET_FULL_DATA,
      cpf: validatedUpdatedUser.cpf,
      email: validatedUpdatedUser.email,
      gender: validatedUpdatedUser.gender,
      name: validatedUpdatedUser.name,
      lastName: validatedUpdatedUser.lastName,
      phone: validatedUpdatedUser.phone,
    });

    if (!!Date.parse(validatedUpdatedUser?.birthdate)) {
      setValue(
        "birthdate",
        format(parseISO(validatedUpdatedUser.birthdate), "dd/MM/yyyy")
      );
      dispatchRegistrationDataState({
        type: RegistrationDataActionKind.UPDATE_BIRTHDATE,
        birthdate: format(
          parseISO(validatedUpdatedUser.birthdate),
          "dd/MM/yyyy"
        ),
      });
    } else if (isDateStringValid(validatedUpdatedUser?.birthdate)) {
      setValue(
        "birthdate",
        defineDateNationality(validatedUpdatedUser.birthdate)
      );
      dispatchRegistrationDataState({
        type: RegistrationDataActionKind.UPDATE_BIRTHDATE,
        birthdate: defineDateNationality(validatedUpdatedUser.birthdate),
      });
    }

    handleGetProvider();
    handleGetUpdatedUser();
  }, []);

  if (registrationDataState === registrationDataReducerInitialState) {
    return <BasicLoading isFullScreen={false} />;
  }

  return (
    <ScrollView>
      <KeyboardAvoidingView>
        <SafeAreaView style={styles.container}>
          <StatusBar barStyle={"light-content"} translucent={true} />
          <HeaderWithGoBack
            goBackFunction={() => {
              setIsEditing(false);
              navigation.navigate("Home");
            }}
            title="Dados Cadastrais"
            icon="x"
          />
          {loadingProfileData ? (
            <BasicLoading />
          ) : (
            <>
              <View style={styles.registrationDataContainer}>
                <View style={styles.registrationDataWrapper}>
                  {!isEditing && (
                    <TouchableOpacity
                      activeOpacity={0.7}
                      style={[
                        styles.editButton,
                        { backgroundColor: colors.primaryColor },
                      ]}
                      onPress={() => setIsEditing(!isEditing)}>
                      <Foundation
                        name="pencil"
                        style={styles.editButtonIcon}
                        onPress={() => setIsEditing(!isEditing)}
                      />
                    </TouchableOpacity>
                  )}
                  <View style={styles.header}>
                    <AntDesign
                      name="idcard"
                      style={[styles.idIcon, { color: colors.primaryColor }]}
                    />
                    <Text style={styles.headerText}>Seus Dados</Text>
                  </View>

                  <View>
                    <ValidationInput
                      control={control}
                      label="Nome"
                      name="name"
                      editable={isEditing}
                      defaultValue={registrationDataState.name}
                      additionalLabelStyles={{ marginTop: 10 }}
                    />
                    <ValidationInput
                      control={control}
                      label="Sobrenome"
                      name="lastName"
                      editable={isEditing}
                      defaultValue={registrationDataState.lastName}
                      additionalLabelStyles={{ marginTop: 10 }}
                    />

                    <ValidationInput
                      control={control}
                      label="CPF"
                      name="cpf"
                      editable={isEditing}
                      defaultValue={registrationDataState.cpf}
                      maskType={"cpf"}
                      inputType="with-mask"
                      additionalLabelStyles={{ marginTop: 10 }}
                    />

                    <ValidationInput
                      control={control}
                      label="Email"
                      name="email"
                      editable={isEditing}
                      defaultValue={registrationDataState.email}
                      additionalLabelStyles={{ marginTop: 10 }}
                      inputMode="email"
                    />
                    <ValidationInput
                      control={control}
                      label="Telefone"
                      name="phone"
                      editable={isEditing}
                      defaultValue={registrationDataState.phone}
                      additionalLabelStyles={{ marginTop: 10 }}
                      maskType="custom"
                      inputType="with-mask"
                      options={{
                        withDDD: true,
                        maskType: "BRL",
                        dddMask: "(99)",
                        mask: "(99) 9 9999-9999",
                      }}
                      inputMode="tel"
                    />

                    <InputWithLabel
                      label="Provedor"
                      onChangeText={() => {}}
                      defaultValue={provider.name}
                      editable={false}
                      disabled={true}
                      additionalLabelStyles={{ marginTop: 10 }}
                    />
                    <ValidationInput
                      control={control}
                      name="birthdate"
                      label="Data de nascimento"
                      editable={isEditing}
                      maskType="datetime"
                      defaultValue={registrationDataState.birthdate}
                      placeholder={
                        Date.parse(updatedUser.birthdate)
                          ? format(
                              parseISO(updatedUser.birthdate),
                              "dd/MM/yyyy"
                            )
                          : ""
                      }
                      inputType={"with-mask"}
                      additionalLabelStyles={{ marginTop: 10 }}
                      maxLength={10}
                    />

                    <View>
                      <Text
                        style={[
                          styles.genderLabel,
                          { color: colors.primaryColor },
                        ]}>
                        Gênero
                      </Text>

                      <View style={styles.inputsRow}>
                        <RadioInput
                          label={"Masculino"}
                          value={"Masculino"}
                          onPress={() => {
                            dispatchRegistrationDataState({
                              type: RegistrationDataActionKind.UPDATE_GENDER,
                              gender: "Masculino",
                            });
                            setValue("gender", "Masculino");
                          }}
                          selectedValue={registrationDataState.gender}
                          additionalContainerStyles={{ marginRight: 10 }}
                          disabled={!isEditing}
                        />
                        <RadioInput
                          label={"Feminino"}
                          value={"Feminino"}
                          onPress={() => {
                            dispatchRegistrationDataState({
                              type: RegistrationDataActionKind.UPDATE_GENDER,
                              gender: "Feminino",
                            });
                            setValue("gender", "Feminino");
                          }}
                          selectedValue={registrationDataState.gender}
                          disabled={!isEditing}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.buttonWrapper}>
                <DefaultButton
                  text={isEditing ? "Salvar" : "Editar"}
                  onPress={async () => {
                    if (isEditing) {
                      await handleEditUser(getValues());
                    } else {
                      setIsEditing(true);
                    }
                  }}
                  additionalStyles={{
                    width: isEditing ? 150 : 300,
                    marginRight: isEditing ? 10 : 0,
                    marginTop: 20,
                  }}
                />
                {isEditing && (
                  <DefaultButton
                    text={"Cancelar"}
                    onPress={() => {
                      setIsEditing(false);
                      dispatchRegistrationDataState({
                        type: RegistrationDataActionKind.SET_FULL_DATA,
                        cpf: updatedUser.cpf,
                        email: updatedUser.email,
                        gender: updatedUser.gender,
                        name: updatedUser.name,
                        lastName: updatedUser.lastName,
                        phone: updatedUser.phone,
                      });
                    }}
                    additionalStyles={{
                      backgroundColor: colors.midGrey,
                      width: 150,
                      marginTop: 20,
                    }}
                  />
                )}
              </View>
            </>
          )}
        </SafeAreaView>
      </KeyboardAvoidingView>
    </ScrollView>
  );

  // return <Text>Heyjkdfgjpowsjgposdjgrfpojfopqwjfpoqwjefpoqwjefpoqwjefpoj</Text>;
};

export { RegistrationData };
