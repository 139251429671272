import { useEffect, useState } from "react";

import { useRoute } from "@react-navigation/native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { Easing, Linking, StatusBar, Text, View } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";

import { getColor } from "../../styles/colors";

import Toast from "react-native-toast-message";
import { DefaultButton } from "../../components/Buttons/DefaultButton";
import { AppointmentInfoCard } from "../../components/Cards/AppointmentInfoCard";
import { HeaderWithGoBack } from "../../components/Headers/HeaderWithGoBack";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

import { differenceInMinutes, parseISO } from "date-fns";
import { SafeAreaView } from "react-native-safe-area-context";
import { useCompany } from "../../hooks/useCompany";
import { Plans, Providers } from "../../models/gleebem.core";
import { deactivateAppointmentApi } from "../../services/api/appointment/deactivateAppointment";
import { isAppointmentOnTime } from "../../utils/appointment";
import { getDatePercentage } from "../../utils/date/getDatePercentage";
import { styles } from "./styles";
import { useAuth } from "../../hooks/useAuth";

interface PageParams {
  appointmentId: string;
  patientName: string;
  appointmentDate: string;
  shouldCount: boolean;
  appointmentStatus: string;
  appointmentLinkRoom: string;
  provider: Providers;
  plan: Plans;
  specialtyId: number;
  specialtyName?: string;
  cancelReason?: string;
}

const AppointmentDetails = ({ navigation }: NativeStackHeaderProps) => {
  const routes = useRoute();
  const { userToken, setLoading } = useAuth();
  const { company } = useCompany();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [missingTimeString, setMissingTimeString] = useState<string>("--:--");
  const [missingPercentage, setMissingPercentage] = useState<number>(100000);
  const [appointmentAvailable, setAppointmentAvailable] =
    useState<boolean>(false);

  const params = routes.params as PageParams;

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  function checkInterval() {
    const currentDate = new Date();
    let appointmentDate = parseISO(params.appointmentDate);
    const timeDifference = differenceInMinutes(appointmentDate, currentDate);

    const isOnTime = isAppointmentOnTime(appointmentDate);

    setAppointmentAvailable(
      isOnTime &&
        (params.appointmentStatus === "open" ||
          params.appointmentStatus === "inProgress")
    );

    if (timeDifference > 0) {
      setMissingPercentage(getDatePercentage(appointmentDate));

      setMissingTimeString(
        Math.trunc(timeDifference / 60).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
          ":" +
          (timeDifference % 60).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })
      );
    } else {
      setMissingPercentage(100);
      setMissingTimeString("00:00");
    }
  }

  useEffect((): any => {
    const unsubscribe = navigation.addListener("focus", checkInterval);
    const intervalId = setInterval(() => checkInterval(), 1000);
    checkInterval();

    return () => {
      clearInterval(intervalId);
      unsubscribe();
    };
  }, []);

  async function handleCancelAppointment() {
    setLoading(true);
    const response: any = await deactivateAppointmentApi(
      {
        id: params?.appointmentId,
        plan: params?.plan,
        provider: params?.provider,
        specialtyId: params?.specialtyId,
      },
      userToken
    );

    if (response.data?.statusCode !== 200) {
      Toast.show({
        type: "error",
        text2: "Erro ao cancelar o agendamento. Por favor, tente novamente.",
      });
    } else {
      Toast.show({ type: "success", text2: "Agendamento cancelado." });
    }
    setShowModal(false);
    navigation.navigate("Histórico");
    setLoading(false);
  }

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar
        barStyle={"light-content"}
        backgroundColor={
          showModal ? colors.transparentBlack : colors.primaryColor
        }
        translucent={true}
      />
      <HeaderWithGoBack
        title="Fila de espera"
        goBackFunction={() => {
          navigation.navigate("Histórico");
        }}
        icon="x"
      />
      <View style={styles.contentWrapper}>
        {params.shouldCount && (
          <AnimatedCircularProgress
            ref={(ref) => {
              ref?.animate(100, missingPercentage, Easing.quad);
            }}
            size={156}
            width={9}
            fill={100}
            tintColor={colors.primaryColor}
            backgroundColor={colors.midGrey}
            lineCap="round">
            {(fill) => (
              <View style={styles.progressCircleBackground}>
                <Text style={styles.progressCircleText}>
                  {missingTimeString}
                </Text>
              </View>
            )}
          </AnimatedCircularProgress>
        )}
        {!["finished"].includes(params.appointmentStatus) && (
          <>
            {["cancelled", "abandon"].includes(params.appointmentStatus) ? (
              <Text style={styles.title}>Atendimento Cancelado</Text>
            ) : (
              <Text style={styles.title}>
                {params.shouldCount
                  ? "Aguarde sua vez"
                  : "Ainda falta muito para seu atendimento"}
              </Text>
            )}
          </>
        )}
        {params?.cancelReason && (
          <Text style={styles.subtitle}>
            Motivo do cancelamento: {params.cancelReason}
          </Text>
        )}
        {!["cancelled", "abandon", "finished"].includes(
          params.appointmentStatus
        ) && (
          <Text style={styles.subtitle}>
            *O link ficará disponível 5 minutos antes da consulta
          </Text>
        )}

        {["finished"].includes(params.appointmentStatus) && (
          <Text style={styles.title}>Consulta finalizada</Text>
        )}

        <AppointmentInfoCard
          patientName={params?.patientName || ""}
          appointmentDate={params?.appointmentDate}
          specialtyName={params?.specialtyName}
        />

        <View style={styles.buttonWrapperLInk}>
          <DefaultButton
            text="Acessar consulta"
            disabled={!appointmentAvailable}
            onPress={() => {
              Linking.openURL(params.appointmentLinkRoom);
            }}
          />
        </View>

        {!["cancelled", "abandon", "finished"].includes(
          params.appointmentStatus
        ) && (
          <View style={styles.buttonWrapper}>
            <DefaultButton
              text="Cancelar agendamento"
              onPress={() => setShowModal(true)}
              textColor={colors.redCancel}
              additionalStyles={{
                borderWidth: 1,
                borderColor: colors.redCancel,
                backgroundColor: "transparent",
              }}
            />
          </View>
        )}
      </View>
      <ConfirmationModal
        title="Cancelar consulta!"
        description="Deseja cancelar a sua consulta?"
        cancelButton={{
          onClick: () => setShowModal(false),
          text: "Não",
        }}
        confirmButton={{
          text: "Sim",
          color: "red",
          onClick: async () => {
            setShowModal(false);
            await handleCancelAppointment();
          },
        }}
        isModalOpen={showModal}
        setIsModalOpen={setShowModal}
      />
    </SafeAreaView>
  );
};

export { AppointmentDetails };
