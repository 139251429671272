import { Platform, StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { getColor } from "../../../styles/colors";

const colors = getColor({ company: companyDefaultTheme });

const isWebDevice = Platform.OS == "web";

const styles = StyleSheet.create({
  button: {
    position: "absolute",
    right: 10,
    bottom: 20,
    zIndex: 5000,
    width: 60,
    height: 60,
    backgroundColor: colors.primaryColor,
    borderRadius: 50,
    marginLeft: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonIcon: {
    fontSize: isWebDevice ? 30 : RFValue(24),
    color: colors.white,
  },
});

export { styles };
