import { Dimensions, Platform, StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const isWebDevice = Platform.OS === "web";

const styles = StyleSheet.create({
  modalWrapper: {
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    marginTop: "auto",
    paddingBottom: 66,
  },
  header: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    backgroundColor: colors.white,
    borderRadius: 20,
    height: 10,
    padding: 16,
    width: "100%",
  },
  closeButton: {
    position: "absolute",
    marginRight: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: isWebDevice ? 30 : RFValue(24),
    color: colors.text,
    fontWeight: "bold",
    alignSelf: "center",
  },
  content: {
    padding: 24,
    flex: 1,
  },
  keyboardAvoidingView: {
    backgroundColor: colors.transparentBlack,
  },
  closeIcon: {
    color: colors.grey,
    width: 40,
    height: 40,
  },
});

export default styles;
