import {
  Roboto_300Light,
  Roboto_500Medium,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";
import "setimmediate";
import { NavigationContainer } from "@react-navigation/native";
import { registerRootComponent } from "expo";
import { RootSiblingParent } from "react-native-root-siblings";
import { AuthContextComponent } from "./contexts/Auth";
import { Routes } from "./route";
import { setTopLevelNavigator } from "./services/navigation";

import * as Notifications from "expo-notifications";
import { useEffect, useRef, useState } from "react";
import { registerForPushNotificationsAsync } from "./controllers/notifcation/registerForPushNotificationsAsync";

import { CompanyThemeComponent } from "./contexts/CompanyTheme";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BasicLoading } from "./components/Loadings/BasicLoading";

import Toast from "react-native-toast-message";
import { CustomToast } from "./components/Toasts/CustomToast";

function App() {
  const [notification, setNotification] =
    useState<Notifications.Notification>();
  const [expoPushToken, setExpoPushToken] = useState<string>("");

  const notificationListener = useRef<any>();
  const responseListener = useRef<any>();

  const [loading, setLoading] = useState<boolean>(true);

  const [fontsLoaded] = useFonts({
    Roboto_700Bold,
    Roboto_500Medium,
    Roboto_300Light,
  });

  useEffect(() => {
    registerForPushNotificationsAsync().then((token) => {
      setExpoPushToken(token || "");
    });

    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        setNotification(notification || {});
      });

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {});

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  if (!fontsLoaded) {
    return <BasicLoading isFullScreen />;
  }

  return (
    <RootSiblingParent>
      <NavigationContainer ref={(ref) => setTopLevelNavigator(ref)}>
        <AuthContextComponent
          pushToken={expoPushToken}
          loading={loading}
          setLoading={setLoading}>
          <CompanyThemeComponent loading={loading} setLoading={setLoading}>
            <Routes />
            <Toast
              position="bottom"
              visibilityTime={3500}
              config={{
                success: (props) => <CustomToast {...props} type="success" />,
                error: (props) => <CustomToast {...props} type="error" />,
                info: (props) => <CustomToast {...props} type="info" />,
              }}
            />
          </CompanyThemeComponent>
        </AuthContextComponent>
      </NavigationContainer>
    </RootSiblingParent>
  );
}

registerRootComponent(App);

serviceWorkerRegistration.register();
