import { View, Text } from "react-native";
import { styles } from "./styles";
import { ToastConfigParams, ToastType } from "react-native-toast-message";
import { useCompany } from "../../../hooks/useCompany";
import { getColor } from "../../../styles/colors";
import { FontAwesome, Foundation, MaterialIcons } from "@expo/vector-icons";
import { ReactNode } from "react";

const CustomToast = (props: ToastConfigParams<any>) => {
  const { text1, text2 } = props;

  const { company } = useCompany();
  const colors = getColor({ company });

  const ToastIconOptions: any = {
    success: (
      <FontAwesome
        name="check-circle-o"
        style={[styles.icon, { color: colors.green }]}
      />
    ),
    info: (
      <Foundation
        name="info"
        style={[styles.icon, { color: colors.primaryColor }]}
      />
    ),
    error: (
      <MaterialIcons
        name="error-outline"
        style={[styles.icon, { color: colors.redCancel }]}
      />
    ),
  };

  if (!text1 && !text2) {
    console.warn("You must provide some text to the toast!");
    return <></>;
  }

  return (
    <View {...props} style={styles.toastContainer}>
      {!!props.type && ToastIconOptions[props.type]}
      <View>
        {!!text1 && <Text style={styles.mainText}>{text1}</Text>}
        {!!text2 && <Text style={styles.description}>{text2}</Text>}
      </View>
    </View>
  );
};

export { CustomToast };
