import { KeyboardAvoidingView, ScrollView, StatusBar } from "react-native";

import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { SafeAreaView } from "react-native-safe-area-context";

import { HeaderWithGoBack } from "../../components/Headers/HeaderWithGoBack";
import { styles } from "./styles";

import { useRoute } from "@react-navigation/native";
import { useEffect, useReducer, useState } from "react";
import Toast from "react-native-toast-message";
import { useAuth } from "../../hooks/useAuth";
import { CreateUserProps } from "../../models/api/user";
import { SimpleApiPropsReturn } from "../../models/core.props-return";
import {
  PreRegistrationFormActionKind,
  preRegistrationFormInitialState,
  preRegistrationFormReducer,
} from "../../reducers/preRegistrations/preRegistrationFormReducer";
import { createUserApi } from "../../services/api/user/createUserApi";
import {
  UpdateUserApiProps,
  updateUserApi,
} from "../../services/api/user/updateUserApi";

import { getCompanyById } from "../../services/firestore/company/getCompanyById";
import { validateObject } from "../../utils/dataValidation/validateObject";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";

import jwt from "expo-jwt";

export interface PreRegistrationPageParams {
  name: string;
  lastName?: string;
  email: string;
  cpf: string;
  registration: string;
  phone: string;
  birthdate: string;
  gender: "Masculino" | "Feminino" | "";
  companyId: string;
  isInvalidUser?: boolean;
}

const pageSteps = {
  1: Step1,
  2: Step2,
  3: Step3,
};

const FillPreRegistration = ({
  navigation,
  ...props
}: NativeStackHeaderProps) => {
  const routes = useRoute();
  const params = routes?.params as PreRegistrationPageParams;
  const { signIn, setLoading } = useAuth();

  const [acceptTermsOfUse, setAcceptTermsOfUse] = useState<boolean>(false);
  const [termsOfUse, setTermsOfUse] = useState<string>("");

  const [preRegistrationState, dispatchPreRegistrationState] = useReducer(
    preRegistrationFormReducer,
    preRegistrationFormInitialState
  );

  const [selectedStep, setSelectedStep] = useState<1 | 2 | 3>(1);

  const SelectedStep = pageSteps[selectedStep];

  useEffect(() => {
    const handleGetCompany = async () => {
      if (!params?.companyId) {
        setAcceptTermsOfUse(true);
        return;
      }

      const company = await getCompanyById(params.companyId);

      if (!company?.data?.termsOfUse) {
        setAcceptTermsOfUse(true);
        return;
      }

      setTermsOfUse(company.data.termsOfUse);
    };

    handleGetCompany();
  }, [params]);

  async function handleApproveUser() {
    const areAllFieldsFilled = Object.values(preRegistrationState).every(
      (value) => !!value
    );

    if (!areAllFieldsFilled) {
      Toast.show({
        type: "error",
        text2: "Preencha todos os campos para prosseguir!",
      });

      return;
    }

    const validatedParams = validateObject(params);

    let apiUserResponse = {} as SimpleApiPropsReturn;

    const createUserData: CreateUserProps = {
      ...validatedParams,
      ...preRegistrationState,
      phone: preRegistrationState?.phone?.replace(/\D+/g, ""),
      isPermanent: true,
    };

    const updateUserData: UpdateUserApiProps = {
      id: validatedParams.id,
      userData: {
        ...validatedParams,
        ...preRegistrationState,
      },
    };

    const userToken = jwt.encode(
      {
        companyId: createUserData.companyId,
        userId: createUserData.id,
      },
      process.env.AUTH_SECRET || ""
    );

    setLoading(true);

    if (params?.isInvalidUser) {
      delete params?.isInvalidUser;
      apiUserResponse = await updateUserApi(updateUserData, userToken);
    } else {
      apiUserResponse = await createUserApi(createUserData, userToken);
    }

    if (apiUserResponse?.error) {
      dispatchPreRegistrationState({
        type: PreRegistrationFormActionKind.CLEAR_DATA,
      });
      Toast.show({
        type: "error",
        text2: "Ocorreu um erro ao cadastrar seu usuário. Tente mais tarde!",
      });
      navigation.navigate("Login");
      setLoading(false);
      return;
    }

    const signUserInResponse = await signIn({
      cpf: preRegistrationState.cpf,
      registration: preRegistrationState.registration,
    });

    if (signUserInResponse?.errorCode) {
      Toast.show({
        type: "error",
        text2: "Ocorreu um erro ao autenticar seu usuário!",
      });
      navigation.navigate("Login");
      setLoading(false);
      return;
    }

    setLoading(false);
    return;
  }

  // index: (1,2,3)

  return (
    <ScrollView>
      <KeyboardAvoidingView>
        <SafeAreaView style={styles.container}>
          <StatusBar barStyle={"light-content"} translucent={true} />
          <HeaderWithGoBack
            goBackFunction={() => {
              dispatchPreRegistrationState({
                type: PreRegistrationFormActionKind.CLEAR_DATA,
              });
              navigation.navigate("Login");
            }}
            title="Cadastro Final"
            icon="arrow"
          />

          <SelectedStep
            navigation={navigation}
            setSelectedStep={setSelectedStep}
            dispatchPreRegistrationState={dispatchPreRegistrationState}
            preRegistrationState={preRegistrationState}
            handleApproveUser={handleApproveUser}
            acceptTermsOfUse={acceptTermsOfUse}
            termsOfUse={termsOfUse}
            setAcceptTermsOfUse={setAcceptTermsOfUse}
            isInvalidUser={!!params.isInvalidUser}
            params={params}
            {...props}
          />
        </SafeAreaView>
      </KeyboardAvoidingView>
    </ScrollView>
  );
};

export { FillPreRegistration };
