import { Fragment, useEffect, useState } from "react";
import {
  Linking,
  RefreshControl,
  ScrollView,
  StatusBar,
  View,
} from "react-native";

import { NativeStackHeaderProps } from "@react-navigation/native-stack";

import { CardWithStatus } from "../../components/Cards/CardsWithStatus/index";
import { HeaderWithGoBack } from "../../components/Headers/HeaderWithGoBack";
import { NoDataMessage } from "../../components/Messages/NoDataMessage";

import { useAuth } from "../../hooks/useAuth";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

import { getColor } from "../../styles/colors";
import { styles } from "./styles";

import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { BasicLoading } from "../../components/Loadings/BasicLoading";

import ConfirmationModal from "../../components/Modals/ConfirmationModal";

import { firestore } from "../../config/firebase";

import SafeAreaView from "react-native-safe-area-view";
import { isAppointmentOnTime } from "../../utils/appointment";

import {
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  subHours,
} from "date-fns";

import { AppointmentProps } from "../../models/appointmentProps";

import { specialtyIdToSpecialtyName } from "../../utils/doc24/appointment";
import { useCompany } from "../../hooks/useCompany";
import { IconProps } from "../../components/Cards/CardsWithStatus/CardWithStatusIcon";

export interface nextAppointmentProps {
  id: string;
  status: "late" | "on-time" | "abandon" | "";
}

interface HandlePressCardProps {
  appointmentAvailable: boolean;
  isInsideDayRange: boolean;
  appointmentDate: Date;
  appointment: AppointmentProps;
}

const History = ({ navigation }: NativeStackHeaderProps) => {
  const { user } = useAuth();
  const { company } = useCompany();

  const [appointments, setAppointments] = useState<AppointmentProps[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  const statusColorAndTextByTheName = {
    cancelled: {
      text: "cancelada",
      color: colors.textRed,
    },
    open: {
      text: "agendada",
      color: colors.textOrange,
    },
    finished: {
      text: "realizada",
      color: colors.textGreen,
    },
    noShow: {
      text: "ausente",
      color: colors.darkGray,
    },
    inProgress: {
      text: "em progresso",
      color: colors.textGreen,
    },
    abandon: {
      text: "desistido",
      color: colors.darkGray,
    },
    default: {
      text: "",
      color: colors.darkGray,
    },
  };

  useEffect(() => {
    setIsFetchingData(true);

    const unsubscribeDrTisAppointments = handleGetAppointments();

    return () => {
      unsubscribeDrTisAppointments();
    };
  }, [user.id, user.cpf]);

  function handleGetAppointments() {
    const collectionRef = collection(firestore, "appointments");

    const cpfsList = [user.cpf];

    user?.dependents?.forEach((dependent) => {
      if (typeof dependent?.cpf === "string") {
        cpfsList.push(dependent?.cpf);
      }
    });

    const appointmentsQuery = query(
      collectionRef,
      where("patient.cpf", "in", cpfsList),
      orderBy("status", "desc"),
      orderBy("appointmentTime", "desc")
    );

    return onSnapshot(appointmentsQuery, (querySnapShot) => {
      const _appointments: AppointmentProps[] = [];

      querySnapShot.docs.map((doc) => {
        const appointment: any = { ...doc.data() };
        _appointments.push(appointment);
      });

      setIsFetchingData(false);
      setAppointments(_appointments);
    });
  }

  async function onRefreshHistory() {
    setIsRefreshing(true);
    handleGetAppointments();
    setIsRefreshing(false);
  }

  function handlePressCard(props: HandlePressCardProps) {
    const {
      appointment,
      appointmentAvailable,
      appointmentDate,
      isInsideDayRange,
    } = props;
    if (appointmentAvailable) {
      if (appointment?.patientRoom) {
        Linking.openURL(appointment?.patientRoom);
      } else {
        setShowModal(true);
      }
    } else {
      navigation.navigate("Detalhes da consulta", {
        appointmentId: appointment?.id,
        patientName: user.name || "",
        shouldCount: isInsideDayRange,
        appointmentDate: appointmentDate?.toISOString(),
        appointmentStatus: appointment?.status,
        appointmentLinkRoom: appointment?.patientRoom,
        cancelReason: appointment?.cancelReason,
        provider: appointment.provider,
        plan: appointment.plan,
        specialtyId: appointment?.specialtyId,
        specialtyName: appointment?.specialtyId
          ? specialtyIdToSpecialtyName[appointment?.specialtyId]
          : "",
      });
    }
  }

  if (isFetchingData) {
    return <BasicLoading />;
  }

  return (
    <SafeAreaView style={styles.container} forceInset={{ top: "always" }}>
      <StatusBar backgroundColor={colors.primaryColor} translucent={true} />
      <HeaderWithGoBack
        title="Histórico"
        description="Atendimento virtual"
        goBackFunction={() => navigation.goBack()}
      />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={onRefreshHistory}
            tintColor={colors.secondaryColor}
            titleColor={colors.secondaryColor}
            title={"Buscando atendimentos..."}
            colors={[colors.secondaryColor, colors.primaryColor]}
          />
        }>
        <View style={styles.contentWrapper}>
          {!!appointments?.length ? (
            appointments
              ?.filter((appointment) => !!appointment.appointmentAt)
              .map((appointment) => {
                const appointmentStatus = !!appointment && appointment?.status;

                let statusColor =
                  statusColorAndTextByTheName[appointmentStatus];

                if (!statusColor) {
                  statusColor = statusColorAndTextByTheName["default"];
                }

                let color = statusColor.color;
                let text = statusColor.text;

                const now = new Date();
                const appointmentDate = appointment?.appointmentAt?.toDate();

                const time = format(appointmentDate, "HH:mm");

                const fullDate = format(appointmentDate, "dd/MM/yyyy");

                const minutesDifference = differenceInMinutes(
                  appointmentDate,
                  now
                );

                const absMinutesDifference = Math.abs(minutesDifference);
                const missOneMinute = absMinutesDifference === 1;

                const lateText = `${absMinutesDifference} minuto${
                  missOneMinute ? "" : "s"
                } de atraso`;

                const onTimeText = `Falta${
                  missOneMinute ? "" : "m"
                } ${absMinutesDifference} minuto${missOneMinute ? "" : "s"}`;
                const subTitleText =
                  minutesDifference < 0 ? lateText : onTimeText;

                const isOnTime = isAppointmentOnTime(appointmentDate);

                const appointmentAvailable =
                  isOnTime && appointment?.status === "open";

                const isInsideDayRange = isBefore(
                  subHours(appointmentDate, 24),
                  now
                );

                const textStatusCard = isAfter(
                  now,
                  appointment?.appointmentAt.toDate()
                )
                  ? "danger"
                  : "warn";

                const iconConfig: IconProps = {
                  color:
                    appointment.status !== "inProgress"
                      ? textStatusCard
                      : "green",
                  type: appointment.status === "inProgress" ? "live" : "clock",
                };

                return (
                  <Fragment key={appointment?.id}>
                    <CardWithStatus.Root
                      onPress={() =>
                        handlePressCard({
                          appointment,
                          appointmentAvailable,
                          appointmentDate,
                          isInsideDayRange,
                        })
                      }>
                      <CardWithStatus.Header
                        status={{ text, color }}
                        flag={
                          appointment?.patient?.cpf !== user.cpf
                            ? "Dependente"
                            : ""
                        }>
                        {(appointmentAvailable ||
                          appointment.status === "inProgress") &&
                          iconConfig?.type &&
                          iconConfig?.color && (
                            <CardWithStatus.Icon icon={iconConfig} />
                          )}
                      </CardWithStatus.Header>
                      <CardWithStatus.Body
                        appointment={appointment}
                        appointmentAvailable={appointmentAvailable}
                        appointmentTimeText={`${fullDate} às ${time}`}
                        isDependent={appointment.patientId !== user.id}
                        textStatusCard={textStatusCard}
                        subTitleText={subTitleText}
                      />
                    </CardWithStatus.Root>
                  </Fragment>
                );
              })
          ) : (
            <View>
              <NoDataMessage text="Você não possui nenhum histórico." />
            </View>
          )}
        </View>
        <ConfirmationModal
          title="Link da consulta indisponível"
          description="Ocorreu algum problema com o link da consulta. Verifique a hora do agendamento."
          dontShowCancelButton
          confirmButton={{
            text: "Ok",
            color: "blue",
            onClick: async () => {
              setShowModal(false);
            },
          }}
          isModalOpen={showModal}
          setIsModalOpen={setShowModal}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

export { History };
