import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { Fragment, useEffect, useState } from "react";

import {
  Linking,
  Platform,
  ScrollView,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { CardWithIcon } from "../../components/Cards/CardWithIcon";
import { MainHeader } from "../../components/Headers/MainHeader";

import { getColor } from "../../styles/colors";
import { styles } from "./styles";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

import { Feather, Ionicons, MaterialIcons } from "@expo/vector-icons";
import SafeAreaView from "react-native-safe-area-view";
import { Avatar } from "../../components/Images/Avatar";
import ModalBottomSheet from "../../components/Modals/ModalBottomSheet";
import { useAuth } from "../../hooks/useAuth";

import Toast from "react-native-toast-message";

import { SupportButton } from "../../components/Buttons/SupportButton";
import { HistoryCard } from "../../components/Cards/HistoryCard";
import { PhoneCard } from "../../components/Cards/PhoneCard";
import { WebViewComponent } from "../../components/WebView";
import { useCompany } from "../../hooks/useCompany";
import { getAssetsSources } from "../../utils/images/sources";
import { createQueryUrl } from "../../utils/url/createQueryUrl";

const Home = ({ navigation }: NativeStackHeaderProps) => {
  const { user, signOut } = useAuth();
  const { company } = useCompany();

  const colors = getColor({ company: company ? company : companyDefaultTheme });
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [url, setUrl] = useState<string>(
    "https://www.gleebem.com.br/?open=true"
  );
  const [showPhoneModal, setShowPhoneModal] = useState<boolean>(false);
  const [showWebView, setShowWebView] = useState<boolean>(false);

  const isWebDevice = Platform.OS === "web";

  useEffect(() => {
    const loadGleebemUrl = async () => {
      if (!!user.id) {
        const urlParams = {
          name: `${user.name} ${user.lastName || ""}`,
          email: user.email,
          phone: user.phone,
          id: user.id,
          open: true,
        };

        const urlWithParameters = createQueryUrl({
          url: "https://www.gleebem.com.br",
          attributesList: urlParams,
        });
        setUrl(urlWithParameters);
      }
    };

    loadGleebemUrl();
  }, [user]);

  async function handleSignOut() {
    setShowProfileModal(false);
    const response = await signOut();

    if (!!response?.errorCode) {
      Toast.show({
        type: "error",
        text2: response.message,
      });
    } else {
      navigation.navigate("Login");
    }
  }

  if (showWebView) {
    if (isWebDevice) {
      Linking.openURL(url);
      setShowWebView(false);
      return;
    }

    return <WebViewComponent setShowWebView={setShowWebView} uri={url} />;
  }

  return (
    <SafeAreaView style={styles.container} forceInset={{ top: "always" }}>
      <StatusBar backgroundColor={colors.primaryColor} translucent={true} />
      <MainHeader onClickMenu={() => setShowProfileModal(true)} />
      <ScrollView showsVerticalScrollIndicator={false}>
        {/* {!!company?.medicalPhone?.length &&
        user.isCallEnabled &&
        user.provider === "dr_tis" && (
          <CallButton
            onPress={() => {
              setShowPhoneModal(true);
            }}
          />
        )} */}
        <View style={styles.textWrapper}>
          <Text style={styles.greetings}>Olá, {user?.name.split(" ")[0]}!</Text>
          <Text style={styles.normalText}>
            Seja atendido agora por um médico da Telemedicina Gleebem
          </Text>
        </View>
        <View style={styles.mainContent}>
          {user.provider === "doc24" && (
            <CardWithIcon
              title="Atendimento Imediato"
              description="Seja atendido agora por um médico"
              icon="plus"
              onPress={() => navigation.navigate("Atendimento Imediato")}
            />
          )}
          <CardWithIcon
            title="Agende sua consulta"
            description="Faça seu agendamento para o melhor horário do seu dia"
            icon="calendar"
            onPress={() => navigation.navigate("Agenda")}
          />

          {Array.isArray(company?.medicalPhone) &&
            user.isCallEnabled &&
            user.provider === "dr_tis" && (
              <CardWithIcon
                title="Atendimento por Ligação"
                description="Efetue uma chamada telefônica com um médico de plantão"
                icon="phone-volume"
                onPress={() => {
                  setShowPhoneModal(true);
                }}
              />
            )}

          <HistoryCard
            textContent="Histórico"
            onPress={() => {
              navigation.navigate("Histórico");
            }}
          />
        </View>

        <ModalBottomSheet
          visible={showProfileModal}
          onRequestClose={() => {
            setShowProfileModal(false);
          }}>
          <View>
            <View style={styles.userInfoWrapper}>
              <Avatar uri={getAssetsSources().profileImage} />
              <Text style={styles.userName}>{user.name}</Text>
            </View>
            <TouchableOpacity
              style={styles.registrationDataWrapper}
              onPress={() => {
                setShowProfileModal(false);
                navigation.navigate("Dados Cadastrais");
              }}>
              <Ionicons
                name="document-text"
                size={24}
                style={[
                  styles.registrationIcon,
                  { color: colors.primaryColor },
                ]}
              />
              <Text style={styles.modalBottomSheetButtonText}>
                Dados Cadastrais
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.companyInfoWrapper}
              onPress={() => {
                setShowProfileModal(false);
                navigation.navigate("Sobre empresa");
              }}>
              <Feather
                name="info"
                size={24}
                style={[styles.infoIcon, { color: colors.primaryColor }]}
              />
              <Text style={styles.modalBottomSheetButtonText}>Sobre</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.logoutWrapper}
              onPress={() => {
                setShowProfileModal(false);
                handleSignOut();
              }}>
              <MaterialIcons name="logout" style={styles.logoutIcon} />
              <Text style={styles.logoutText}>Sair</Text>
            </TouchableOpacity>
          </View>
        </ModalBottomSheet>
        <ModalBottomSheet
          visible={showPhoneModal}
          onRequestClose={() => {
            setShowPhoneModal(false);
          }}>
          <View>
            <Text style={[styles.title, { marginBottom: 10 }]}>
              Selecione um número para ligar:
            </Text>
            <ScrollView persistentScrollbar>
              {company?.medicalPhone?.map((phoneData) => (
                <Fragment
                  key={`${phoneData?.phone}_${phoneData?.stateAcronym}`}>
                  <PhoneCard
                    phone={phoneData?.phone}
                    stateAcronym={phoneData?.stateAcronym}
                  />
                </Fragment>
              ))}
            </ScrollView>
          </View>
        </ModalBottomSheet>
      </ScrollView>
      <SupportButton setShowSupportOptions={setShowWebView} />
    </SafeAreaView>
  );
};

export { Home };
