import { Link } from "@react-navigation/native";

import { useReducer, useRef, useState } from "react";
import {
  Dimensions,
  KeyboardAvoidingView,
  Linking,
  Platform,
  ScrollView,
  StatusBar,
  Text,
  TextInput,
  Touchable,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import Toast from "react-native-toast-message";

import { TextInputMask } from "react-native-masked-text";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";
import { DefaultButton } from "../../components/Buttons/DefaultButton";
import { HeaderWithPhotos } from "../../components/Headers/HeaderWithPhotos";
import { useAuth } from "../../hooks/useAuth";
import {
  LoginFormActionKind,
  loginFormInitialState,
  loginFormReducer,
} from "../../reducers/login/loginFormReducer";
import { getColor } from "../../styles/colors";
import { styles } from "./styles";

import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import SafeAreaView from "react-native-safe-area-view";
import { SupportButton } from "../../components/Buttons/SupportButton";
import { WebViewComponent } from "../../components/WebView";
import { useCompany } from "../../hooks/useCompany";
import { getPreRegistration } from "../../services/firestore/user/getPreRegistration";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Feather } from "@expo/vector-icons";

const Login = ({ navigation }: NativeStackHeaderProps) => {
  const { signIn } = useAuth();
  const { company } = useCompany();

  const [formState, dispatchForm] = useReducer(
    loginFormReducer,
    loginFormInitialState
  );
  const scrollViewRef = useRef<any>();

  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  const [isUserNoneExistent, setIsUserNoneExistent] = useState<boolean>(false);

  const [showWebView, setShowWebView] = useState<boolean>(false);

  const [showRegistration, setShowRegistration] = useState<boolean>(false);

  const defaultUrl = "https://www.gleebem.com.br/?open=true";

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  const isWebDevice = Platform.OS === "web";

  const activeInputStyle = {
    borderWidth: 1,
    borderColor: colors.neutralGray,
  };

  function handleBlur() {
    dispatchForm({ type: LoginFormActionKind.CLEAR_INPUT_ACTIVE });
  }

  async function handleLogin() {
    if (formState.cpf && formState.registration) {
      setIsFetchingData(true);

      const formattedCpf = formState.cpf.replace(/\D+/g, "");

      const formattedRegistration = formState.registration.trim();

      const response = await signIn({
        cpf: formattedCpf,
        registration: formattedRegistration,
      });

      if (
        response?.errorCode === "403" &&
        response?.errorMessage?.includes("INVALID_DATA")
      ) {
        Toast.show({ type: "error", text2: response.message });
        navigation.navigate("pre_registration", {
          ...response?.data,
          isInvalidUser: true,
        });
        dispatchForm({ type: LoginFormActionKind.CLEAR_DATA });
        setIsFetchingData(false);
        return;
      }

      if (!response?.data?.cpf) {
        const preRegistration = await getPreRegistration({
          cpf: formattedCpf,
          registration: formattedRegistration,
        });
        if (!!preRegistration.data?.id && !preRegistration?.errorCode) {
          navigation.navigate("pre_registration", {
            ...preRegistration.data,
          });
          Toast.show({
            type: "info",
            text2: "Atualize os dados do seu usuário para entrar no app!",
          });
          dispatchForm({ type: LoginFormActionKind.CLEAR_DATA });
          setIsUserNoneExistent(false);
        } else {
          setIsUserNoneExistent(true);
          if (Platform.OS !== "web") {
            Toast.show({
              type: "error",
              text2: "Cadastro não encontrado. Por favor, tente novamente.",
            });
          }
        }
      }
      setIsFetchingData(false);
    }
  }

  if (showWebView) {
    if (isWebDevice) {
      Linking.openURL(defaultUrl);
      setShowWebView(false);
      return;
    }

    return (
      <WebViewComponent setShowWebView={setShowWebView} uri={defaultUrl} />
    );
  }

  return (
    <SafeAreaView style={{ flex: 1 }} forceInset={{ top: "always" }}>
      <StatusBar
        barStyle={"light-content"}
        backgroundColor={colors.primaryColor}
        translucent={true}
      />

      <SupportButton setShowSupportOptions={setShowWebView} />
      <ScrollView style={styles.scrollView} ref={scrollViewRef}>
        <HeaderWithPhotos bannerURL={company.banner} logoURL={company.logo} />
        <View style={styles.container}>
          <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : "height"}>
            <TouchableWithoutFeedback>
              <View style={styles.contentWrapper}>
                <View>
                  <Text style={styles.instructionTitle}>
                    Que bom que você está aqui
                  </Text>
                  <Text style={styles.instructionDescription}>
                    Preencha com seus dados para fazer login.
                  </Text>
                </View>

                <View style={styles.formWrapper}>
                  {isUserNoneExistent && (
                    <Text style={styles.errorText}>
                      Usuário não encontrado!
                    </Text>
                  )}
                  <TextInputMask
                    type="cpf"
                    placeholder="CPF"
                    style={[
                      styles.input,
                      formState.inputActive === "cpf" && activeInputStyle,
                    ]}
                    onChangeText={(currentText) => {
                      dispatchForm({
                        type: LoginFormActionKind.SET_CPF,
                        cpf: currentText,
                      });
                    }}
                    value={formState.cpf}
                    onFocus={() => {
                      dispatchForm({
                        type: LoginFormActionKind.SET_INPUT_ACTIVE,
                        inputActive: "cpf",
                      });
                      scrollViewRef.current.scrollToEnd({ animated: true });
                    }}
                    onBlur={handleBlur}
                  />
                  <View
                    style={[
                      styles.registrationInputWrapper,
                      formState.inputActive === "registration" &&
                      activeInputStyle,
                    ]}>
                    <TextInput
                      keyboardType="numeric"
                      secureTextEntry={!showRegistration}
                      placeholder="Matrícula"
                      style={[styles.registrationInput]}
                      onChangeText={(currentText) => {
                        dispatchForm({
                          type: LoginFormActionKind.SET_REGISTRATION,
                          registration: currentText,
                        });
                      }}
                      onFocus={() => {
                        dispatchForm({
                          type: LoginFormActionKind.SET_INPUT_ACTIVE,
                          inputActive: "registration",
                        });

                        scrollViewRef.current.scrollToEnd({ animated: true });
                      }}
                      onBlur={handleBlur}
                      value={formState.registration}
                    />
                    <TouchableOpacity
                      style={styles.eyeButton}
                      activeOpacity={0.7}
                      onPress={() => setShowRegistration(!showRegistration)}>
                      <Feather
                        name={showRegistration ? "eye-off" : "eye"}
                        style={styles.eyeIcon}
                        color={colors.primaryColor}
                      />
                    </TouchableOpacity>
                  </View>
                  <Link
                    to={{ screen: "Recuperar Matrícula" }}
                    style={styles.recoverRegistrationText}>
                    Recuperar matrícula
                  </Link>
                  <DefaultButton
                    text="Continuar"
                    onPress={async () => {
                      await handleLogin();
                    }}
                    additionalStyles={{
                      marginTop: 20,
                      marginBottom: 10,
                      margin: "auto",
                      alignSelf: "center",
                    }}
                    disabled={!formState.cpf || !formState.registration}
                    isLoading={isFetchingData}
                  />
                </View>
              </View>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export { Login };
